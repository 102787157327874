import React from 'react';

export function NotFoundScene() {
    return (
        <div className="content">
            <h2>Cette page n'existe pas !!</h2>

            {/* TODO: faire page 404 */}

        </div>
    );
}
